import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >
        <path d="M2000 7868 c-24 -26 -28 -99 -5 -118 8 -6 32 -14 54 -17 33 -5 44 -1
65 20 32 32 34 74 5 111 -27 34 -88 37 -119 4z"/>
<path d="M2888 7872 c-58 -35 -28 -122 43 -122 57 0 80 82 33 119 -32 25 -41
26 -76 3z"/>
<path d="M2514 7730 c-79 -32 -68 -168 14 -171 52 -2 67 2 89 29 39 46 29 97
-27 135 -27 19 -44 20 -76 7z"/>
<path d="M3602 7657 c-19 -9 -22 -17 -20 -45 4 -44 27 -60 65 -46 20 7 29 17
31 37 6 47 -32 74 -76 54z"/>
<path d="M3260 7617 c-76 -38 -23 -159 58 -131 55 19 66 110 16 133 -31 14
-43 14 -74 -2z"/>
<path d="M1648 7602 c-42 -22 -60 -56 -55 -109 7 -69 61 -107 128 -91 54 14
79 49 79 108 0 37 -5 51 -24 68 -40 34 -89 43 -128 24z"/>
<path d="M2924 7515 c-35 -24 -48 -68 -30 -102 8 -16 22 -36 32 -44 22 -18 90
-16 113 5 37 33 35 101 -4 139 -20 21 -83 22 -111 2z"/>
<path d="M2132 7477 c-55 -56 -41 -136 30 -173 41 -21 84 -12 125 28 41 39 45
90 11 134 -31 41 -37 44 -89 44 -38 0 -50 -5 -77 -33z"/>
<path d="M2609 7349 c-41 -44 -41 -100 2 -140 23 -21 41 -29 69 -29 42 0 99
43 101 77 5 76 -35 123 -102 123 -33 0 -47 -6 -70 -31z"/>
<path d="M3540 7310 c-25 -25 -26 -72 -2 -93 10 -10 34 -17 53 -17 28 0 38 6
52 30 42 70 -46 137 -103 80z"/>
<path d="M3811 7316 c-7 -8 -11 -30 -9 -48 3 -31 5 -33 45 -36 37 -3 44 0 54
20 7 16 7 32 0 51 -9 22 -17 27 -45 27 -18 0 -39 -6 -45 -14z"/>
<path d="M4098 7293 c-39 -10 -32 -69 10 -80 27 -6 55 19 50 46 -4 19 -39 39
-60 34z"/>
<path d="M3275 7255 c-32 -31 -32 -65 0 -99 48 -52 125 -22 125 49 0 70 -76
100 -125 50z"/>
<path d="M1312 7248 c-74 -52 -65 -174 15 -216 31 -17 115 -15 141 4 29 20 52
65 52 102 0 79 -53 132 -131 132 -30 0 -55 -7 -77 -22z"/>
<path d="M1863 7225 c-36 -15 -60 -47 -69 -93 -9 -44 0 -72 36 -109 26 -28 38
-33 76 -33 53 0 80 12 111 49 44 52 20 151 -46 186 -37 18 -66 19 -108 0z"/>
<path d="M2373 7175 c-42 -18 -63 -52 -63 -103 0 -108 138 -151 198 -62 30 45
26 111 -9 145 -30 28 -85 37 -126 20z"/>
<path d="M3023 7168 c-31 -34 -36 -63 -18 -97 45 -87 155 -59 155 40 0 52 -27
79 -78 79 -27 0 -45 -7 -59 -22z"/>
<path d="M2781 7034 c-29 -37 -27 -85 5 -123 31 -38 81 -42 125 -10 25 17 29
27 29 65 0 62 -32 94 -94 94 -36 0 -48 -5 -65 -26z"/>
<path d="M3723 6990 c-50 -20 -32 -110 22 -110 24 0 65 41 65 66 0 12 -46 55
-59 53 -3 0 -16 -4 -28 -9z"/>
<path d="M3501 6954 c-12 -15 -21 -30 -21 -34 0 -18 43 -60 61 -60 41 0 59 19
59 60 0 29 -5 42 -19 50 -32 17 -58 12 -80 -16z"/>
<path d="M3908 6944 c-16 -34 0 -58 40 -62 26 -3 34 2 43 21 16 36 -3 67 -42
67 -22 0 -32 -6 -41 -26z"/>
<path d="M2110 6910 c-61 -65 -44 -152 37 -190 45 -21 47 -21 85 -4 82 37 105
130 49 192 -27 29 -35 32 -86 32 -50 0 -60 -4 -85 -30z"/>
<path d="M3301 6914 c-26 -34 -26 -54 -1 -82 41 -44 120 -18 120 39 0 30 -39
69 -70 69 -19 0 -36 -9 -49 -26z"/>
<path d="M4127 6912 c-23 -25 -21 -49 3 -62 44 -23 80 29 44 64 -20 20 -27 20
-47 -2z"/>
<path d="M1625 6898 c-11 -6 -32 -22 -47 -36 -25 -23 -28 -33 -28 -90 0 -59 2
-66 34 -98 30 -30 40 -34 88 -34 71 0 119 32 137 89 13 44 3 99 -23 133 -30
39 -119 59 -161 36z"/>
<path d="M2579 6871 c-36 -37 -41 -99 -9 -129 41 -38 66 -46 101 -32 49 21 69
48 69 95 0 87 -99 128 -161 66z"/>
<path d="M1079 6858 c-54 -30 -84 -103 -68 -162 24 -86 97 -125 188 -101 29 7
54 15 55 17 50 72 58 135 26 195 -37 69 -127 92 -201 51z"/>
<path d="M3106 6838 c-43 -60 9 -131 78 -108 80 26 64 122 -22 128 -32 2 -43
-1 -56 -20z"/>
<path d="M4317 6826 c-8 -20 2 -46 17 -46 21 0 38 18 34 36 -4 24 -43 31 -51
10z"/>
<path d="M2936 6738 c-17 -24 -21 -65 -8 -84 36 -54 132 -27 132 38 0 61 -90
95 -124 46z"/>
<path d="M2443 6703 c-60 -12 -95 -114 -55 -163 32 -40 121 -42 161 -4 9 8 15
34 15 63 1 41 -4 55 -24 75 -27 26 -61 37 -97 29z"/>
<path d="M1925 6636 c-32 -32 -35 -39 -35 -94 0 -55 3 -63 31 -86 37 -31 68
-40 114 -33 42 7 69 31 90 81 21 50 12 87 -31 131 -30 31 -40 35 -84 35 -43 0
-54 -5 -85 -34z"/>
<path d="M2782 6617 c-64 -68 26 -167 99 -108 16 13 23 29 23 54 0 68 -77 102
-122 54z"/>
<path d="M3500 6615 c-23 -24 -23 -25 -5 -45 22 -24 63 -26 72 -3 7 17 -15 64
-33 70 -5 2 -21 -8 -34 -22z"/>
<path d="M3642 6631 c-8 -5 -12 -21 -10 -37 2 -23 9 -30 30 -32 22 -3 30 2 39
22 19 41 -20 72 -59 47z"/>
<path d="M3780 6605 c-6 -8 -9 -23 -5 -35 10 -31 59 -24 63 9 6 37 -34 55 -58
26z"/>
<path d="M3360 6590 c-6 -11 -8 -31 -4 -43 19 -63 85 -27 72 40 -5 27 -54 30
-68 3z"/>
<path d="M3918 6579 c-35 -20 -8 -67 32 -54 22 7 27 34 8 53 -14 14 -18 14
-40 1z"/>
<path d="M3235 6554 c-9 -9 -15 -28 -13 -43 3 -22 8 -26 41 -29 36 -3 37 -2
37 30 0 54 -32 74 -65 42z"/>
<path d="M1490 6543 c-94 -29 -130 -70 -130 -150 0 -46 4 -55 44 -98 45 -47
45 -48 100 -43 89 7 154 76 148 156 -2 25 -28 79 -46 97 -26 27 -88 47 -116
38z"/>
<path d="M4046 6523 c-4 -9 0 -23 9 -32 13 -14 17 -14 32 1 13 14 14 20 3 32
-16 21 -36 20 -44 -1z"/>
<path d="M3094 6475 c-9 -37 24 -70 61 -61 23 6 26 11 23 44 -3 35 -5 37 -40
40 -33 3 -38 0 -44 -23z"/>
<path d="M2272 6469 c-58 -38 -68 -98 -25 -149 35 -42 61 -51 113 -38 34 9 45
18 56 44 22 53 18 90 -15 125 -39 40 -85 47 -129 18z"/>
<path d="M2646 6469 c-34 -27 -34 -68 0 -103 65 -65 152 2 113 88 -19 40 -72
47 -113 15z"/>
<path d="M4170 6445 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M923 6433 c-24 -8 -43 -20 -43 -27 0 -7 -9 -21 -20 -31 -16 -15 -20
-31 -20 -82 0 -79 18 -117 70 -143 80 -41 192 -14 224 55 21 43 21 138 1 159
-8 8 -15 20 -15 26 0 15 -99 60 -130 59 -14 0 -44 -7 -67 -16z"/>
<path d="M2994 6422 c-18 -12 -25 -42 -14 -62 23 -42 90 -31 90 15 0 41 -44
69 -76 47z"/>
<path d="M1814 6351 c-66 -40 -82 -106 -44 -180 15 -31 29 -42 67 -55 45 -16
49 -15 93 6 36 19 49 32 64 69 24 59 16 91 -33 141 -32 31 -46 38 -77 38 -22
0 -53 -9 -70 -19z"/>
<path d="M2880 6310 c-15 -28 -3 -59 25 -66 33 -8 65 12 65 41 0 43 -70 62
-90 25z"/>
<path d="M2532 6300 c-44 -42 -9 -130 51 -130 40 0 77 36 77 75 0 63 -81 98
-128 55z"/>
<path d="M2162 6224 c-37 -25 -53 -66 -41 -106 22 -83 90 -110 159 -64 38 25
40 28 40 80 0 48 -3 57 -31 80 -36 30 -92 35 -127 10z"/>
<path d="M2792 6203 c-12 -22 1 -61 23 -70 9 -3 28 -1 41 6 21 9 25 17 22 44
-3 29 -6 32 -40 35 -27 2 -39 -1 -46 -15z"/>
<path d="M1340 6136 c-84 -34 -117 -114 -81 -199 26 -60 68 -87 137 -87 95 0
160 71 150 164 -7 66 -40 107 -103 127 -48 15 -52 15 -103 -5z"/>
<path d="M2461 6127 c-29 -14 -44 -59 -31 -92 20 -52 98 -60 133 -13 46 62
-31 142 -102 105z"/>
<path d="M2726 6095 c-30 -31 -10 -75 34 -75 24 0 31 5 36 27 13 49 -35 82
-70 48z"/>
<path d="M1729 6025 c-33 -18 -69 -77 -69 -114 0 -138 196 -180 255 -55 29 62
-3 152 -62 175 -35 13 -94 10 -124 -6z"/>
<path d="M833 5975 c-49 -21 -93 -96 -93 -157 0 -62 20 -101 72 -138 76 -55
190 -31 246 51 20 29 23 44 20 100 -3 57 -8 71 -33 99 -54 58 -140 77 -212 45z"/>
<path d="M2088 5957 c-45 -36 -52 -71 -27 -122 26 -51 60 -69 113 -60 80 15
113 111 60 174 -22 27 -33 31 -72 31 -34 0 -55 -6 -74 -23z"/>
<path d="M2664 5953 c-9 -34 6 -57 39 -61 33 -4 51 26 37 62 -7 20 -16 26 -40
26 -24 0 -31 -5 -36 -27z"/>
<path d="M2413 5940 c-31 -12 -46 -47 -39 -88 7 -43 19 -52 72 -52 33 0 45 5
58 25 22 34 20 68 -6 92 -29 26 -57 34 -85 23z"/>
<path d="M2627 5832 c-22 -25 -21 -49 2 -62 31 -16 49 -12 66 14 33 50 -29 92
-68 48z"/>
<path d="M5950 5204 l0 -554 100 0 100 0 -2 552 -3 551 -97 3 -98 2 0 -554z"/>
<path d="M6365 5752 c-3 -3 -5 -252 -5 -554 l0 -548 100 0 100 0 0 550 c0 303
-3 550 -7 551 -32 5 -183 5 -188 1z"/>
<path d="M1275 5719 c-55 -39 -70 -68 -70 -136 0 -60 2 -64 43 -105 41 -42 44
-43 110 -43 58 0 72 4 105 29 40 31 51 56 51 127 0 58 -35 116 -80 135 -51 21
-124 18 -159 -7z"/>
<path d="M2361 5714 c-29 -37 -27 -73 6 -102 33 -28 75 -24 104 10 23 25 23
38 5 83 -14 32 -19 35 -55 35 -31 0 -44 -6 -60 -26z"/>
<path d="M5625 5735 c-5 -2 -23 -6 -38 -9 -32 -8 -67 -58 -67 -98 0 -43 47
-88 99 -95 108 -15 174 95 102 168 -16 16 -36 29 -44 29 -9 0 -21 2 -29 4 -7
3 -17 3 -23 1z"/>
<path d="M2074 5702 c-62 -39 -66 -118 -9 -172 20 -19 32 -22 72 -17 68 7 97
43 90 110 -5 59 -30 87 -83 93 -28 4 -48 -1 -70 -14z"/>
<path d="M1698 5685 c-48 -32 -58 -52 -58 -112 0 -55 15 -82 64 -112 86 -53
196 11 196 115 0 53 -47 119 -91 128 -53 10 -73 7 -111 -19z"/>
<path d="M2604 5695 c-20 -51 32 -93 69 -56 18 17 19 24 9 46 -9 19 -19 25
-42 25 -18 0 -33 -6 -36 -15z"/>
<path d="M4137 5693 c-4 -3 -7 -175 -7 -382 0 -217 -4 -391 -10 -412 -21 -76
-103 -116 -185 -89 -39 13 -75 59 -75 97 0 46 -5 48 -113 47 l-102 -1 2 -45
c8 -144 110 -244 271 -266 143 -20 261 16 341 104 81 88 81 89 81 551 l0 403
-98 0 c-54 0 -102 -3 -105 -7z"/>
<path d="M4567 5693 c-4 -3 -7 -240 -7 -526 l0 -519 268 4 c302 5 333 11 417
82 134 111 122 352 -21 427 -24 13 -44 26 -43 29 0 3 19 19 42 35 23 17 52 50
65 75 45 90 21 243 -49 307 -38 35 -129 72 -204 83 -78 11 -458 14 -468 3z
m444 -173 c56 -16 82 -51 87 -118 4 -52 1 -62 -23 -90 -36 -42 -81 -54 -200
-50 l-100 3 -3 119 c-1 66 0 126 2 133 7 16 182 18 237 3z m33 -424 c68 -28
101 -124 68 -201 -23 -56 -67 -75 -184 -82 -58 -3 -117 -3 -132 1 l-26 6 0
139 c0 76 3 141 7 144 13 13 232 7 267 -7z"/>
<path d="M6925 5633 c-89 -31 -166 -135 -181 -246 -7 -47 -7 -47 23 -47 25 0
33 6 49 42 35 77 95 118 174 118 52 0 92 -21 127 -66 22 -30 28 -49 31 -103 8
-134 -69 -262 -290 -485 -107 -108 -148 -156 -148 -173 l0 -23 296 2 296 3 34
95 c19 52 34 101 34 108 0 21 -58 15 -70 -8 -22 -40 -68 -54 -202 -58 -167 -6
-173 -3 -117 51 119 115 227 255 281 366 72 146 64 263 -23 350 -22 22 -60 49
-84 61 -60 29 -165 35 -230 13z"/>
<path d="M7840 5588 c-24 -35 -88 -126 -142 -203 -267 -377 -258 -362 -258
-427 l0 -58 188 0 c103 0 192 -4 197 -8 6 -4 10 -57 10 -122 l0 -115 77 -3 77
-3 3 123 3 123 63 3 62 3 0 54 c0 81 -3 85 -71 85 l-59 0 -2 303 -3 302 -51 3
-50 3 -44 -63z m-7 -365 c-2 -97 -5 -178 -7 -180 -2 -2 -60 -2 -130 -1 l-127
3 127 178 c70 97 130 177 134 177 4 0 5 -80 3 -177z"/>
<path d="M8292 5613 c-5 -10 -28 -67 -52 -126 -35 -89 -40 -111 -29 -118 26
-16 44 -8 72 32 40 58 78 70 239 77 114 4 138 3 143 -10 3 -8 -55 -197 -130
-420 -74 -224 -135 -409 -135 -412 0 -3 27 -6 59 -6 l59 0 123 368 c172 513
201 604 195 619 -4 10 -60 13 -270 13 -243 0 -266 -1 -274 -17z"/>
<path d="M2612 5558 c-30 -30 0 -75 43 -64 19 5 25 13 25 35 0 38 -41 56 -68
29z"/>
<path d="M845 5516 c-98 -43 -141 -156 -95 -247 57 -111 197 -134 288 -46 59
57 69 156 24 222 -52 74 -143 104 -217 71z"/>
<path d="M2354 5499 c-21 -42 -11 -73 30 -93 45 -21 52 -20 81 9 50 49 19 115
-55 115 -34 0 -42 -4 -56 -31z"/>
<path d="M2077 5430 c-85 -67 -5 -214 98 -180 51 17 70 44 70 102 0 43 -4 53
-30 74 -39 31 -102 32 -138 4z"/>
<path d="M2624 5419 c-23 -26 -9 -53 31 -57 44 -6 57 26 25 58 -25 25 -32 25
-56 -1z"/>
<path d="M5537 5424 c-4 -4 -7 -180 -7 -391 l0 -383 105 0 105 0 -2 388 -3
387 -95 3 c-53 1 -99 0 -103 -4z"/>
<path d="M1761 5369 c-50 -20 -78 -49 -91 -93 -16 -57 -9 -82 33 -129 31 -33
40 -37 82 -37 62 0 110 24 129 65 36 75 9 156 -61 186 -50 21 -58 22 -92 8z"/>
<path d="M2430 5333 c-31 -12 -50 -37 -50 -68 0 -41 27 -65 72 -65 43 0 68 25
68 68 0 26 -7 36 -32 52 -35 21 -35 21 -58 13z"/>
<path d="M1325 5316 c-17 -7 -44 -28 -59 -47 -26 -30 -28 -38 -24 -102 4 -68
5 -71 47 -109 40 -35 48 -38 105 -38 100 0 151 44 163 138 4 41 1 57 -18 91
-39 70 -137 101 -214 67z"/>
<path d="M2672 5288 c-17 -17 -15 -55 4 -62 42 -16 74 43 35 64 -24 13 -24 13
-39 -2z"/>
<path d="M2151 5176 c-89 -49 -53 -186 49 -186 33 0 48 6 71 29 81 82 -19 213
-120 157z"/>
<path d="M2725 5156 c-11 -8 -16 -19 -12 -30 9 -24 36 -31 54 -14 12 12 13 20
4 37 -14 25 -21 26 -46 7z"/>
<path d="M2466 5118 c-20 -29 -20 -72 0 -92 22 -23 70 -20 94 6 26 29 25 59
-3 86 -30 29 -71 28 -91 0z"/>
<path d="M912 5050 c-91 -37 -129 -165 -79 -260 27 -51 97 -92 156 -93 88 -2
171 63 186 147 15 77 -26 166 -93 200 -34 18 -132 21 -170 6z"/>
<path d="M1819 5030 c-92 -49 -90 -189 4 -233 73 -35 144 -8 177 66 24 53 24
58 0 110 -34 72 -109 95 -181 57z"/>
<path d="M2794 5025 c-7 -17 2 -45 14 -45 4 0 17 6 27 14 16 12 17 16 6 30
-17 20 -40 21 -47 1z"/>
<path d="M2572 4944 c-16 -11 -22 -25 -22 -55 0 -33 4 -41 26 -49 67 -26 114
55 58 99 -32 25 -33 25 -62 5z"/>
<path d="M2253 4930 c-27 -11 -53 -59 -53 -97 1 -51 42 -83 109 -83 43 0 75
29 88 78 17 69 -71 131 -144 102z"/>
<path d="M1399 4891 l-44 -39 0 -82 0 -82 39 -34 c70 -61 161 -56 230 13 34
34 36 40 36 101 0 54 -4 71 -26 99 -34 45 -73 63 -137 63 -49 0 -59 -4 -98
-39z"/>
<path d="M2884 4915 c-3 -8 -3 -19 1 -25 11 -18 25 -11 25 15 0 27 -17 34 -26
10z"/>
<path d="M2676 4764 c-10 -26 -7 -69 6 -82 7 -7 24 -12 38 -12 34 0 50 17 50
55 0 17 -5 36 -12 43 -18 18 -75 15 -82 -4z"/>
<path d="M2005 4735 c-5 -2 -22 -6 -37 -9 -39 -9 -78 -70 -78 -123 0 -36 6
-49 39 -83 33 -34 45 -40 80 -40 86 0 132 43 132 123 0 71 -10 90 -64 116 -43
21 -52 23 -72 16z"/>
<path d="M2376 4684 c-28 -28 -38 -55 -32 -90 17 -86 132 -99 170 -20 30 63
-10 126 -80 126 -25 0 -49 -6 -58 -16z"/>
<path d="M1125 4623 c-67 -14 -90 -30 -129 -88 -36 -53 -36 -139 -2 -194 37
-57 77 -82 146 -88 51 -5 65 -2 104 21 24 14 54 37 65 52 25 32 36 118 22 171
-14 50 -74 107 -128 122 -39 11 -45 12 -78 4z"/>
<path d="M2826 4614 c-9 -8 -16 -24 -16 -34 0 -10 7 -26 16 -34 30 -30 84 -9
84 33 0 22 -30 51 -53 51 -8 0 -23 -7 -31 -16z"/>
<path d="M1574 4516 c-105 -105 -44 -276 98 -276 96 0 158 55 166 146 4 42 0
60 -18 89 -35 56 -73 75 -150 74 -59 -1 -68 -4 -96 -33z"/>
<path d="M2990 4486 c-6 -8 -10 -25 -8 -38 2 -19 9 -23 38 -23 29 0 36 4 38
23 2 14 -4 29 -15 37 -23 18 -38 18 -53 1z"/>
<path d="M2542 4467 c-49 -52 -13 -137 57 -137 44 0 66 13 81 50 15 35 0 84
-31 100 -33 18 -84 12 -107 -13z"/>
<path d="M2152 4439 c-78 -39 -95 -138 -36 -200 24 -25 35 -29 85 -29 70 0
106 25 119 83 24 108 -73 193 -168 146z"/>
<path d="M6097 4434 c-4 -4 -7 -108 -7 -231 0 -217 1 -223 20 -223 11 0 23 7
26 15 5 13 9 13 34 0 45 -23 103 -19 134 10 36 33 46 65 46 141 0 125 -71 189
-165 150 -21 -9 -39 -16 -41 -16 -2 0 -4 35 -6 77 -2 61 -6 78 -18 81 -9 1
-19 0 -23 -4z m158 -180 c26 -10 45 -60 45 -119 0 -50 -4 -60 -30 -87 -26 -25
-36 -29 -63 -24 -52 10 -67 37 -67 120 0 59 4 76 20 94 20 22 61 28 95 16z"/>
<path d="M6555 4418 c-3 -13 -4 -115 -3 -228 l3 -205 25 0 25 0 0 225 c0 225
0 225 -22 228 -16 2 -24 -3 -28 -20z"/>
<path d="M6697 4426 c-3 -8 -5 -111 -4 -228 l2 -213 25 0 25 0 0 225 c0 222 0
225 -21 228 -12 2 -24 -4 -27 -12z"/>
<path d="M7483 4428 c-6 -7 -11 -41 -10 -75 0 -35 -2 -63 -6 -63 -4 -1 -23 4
-43 9 -49 13 -87 4 -117 -28 -67 -72 -60 -219 13 -271 33 -24 106 -26 134 -5
17 13 21 13 31 -2 7 -10 19 -14 29 -10 15 6 16 29 14 229 -3 194 -5 223 -19
226 -9 1 -21 -3 -26 -10z m-29 -183 c15 -11 18 -27 18 -92 -1 -43 -1 -84 -1
-90 -1 -21 -60 -45 -92 -38 -66 15 -88 156 -33 216 20 23 80 25 108 4z"/>
<path d="M6420 4405 c-17 -20 -5 -45 20 -45 11 0 23 7 26 15 6 15 -11 45 -26
45 -4 0 -13 -7 -20 -15z"/>
<path d="M5962 4158 c3 -217 5 -253 18 -253 13 0 15 36 18 253 2 248 2 252
-18 252 -20 0 -20 -4 -18 -252z"/>
<path d="M7147 4404 c-4 -4 -7 -117 -7 -251 0 -214 2 -244 16 -250 9 -3 20 0
25 8 5 8 8 122 6 254 -2 207 -4 240 -17 243 -9 1 -19 0 -23 -4z"/>
<path d="M3182 4381 c-20 -12 -10 -46 13 -46 14 0 20 7 20 24 0 27 -13 35 -33
22z"/>
<path d="M5467 4373 c-4 -3 -7 -21 -7 -39 0 -28 -4 -33 -27 -36 -36 -4 -37
-32 -2 -36 l27 -3 3 -124 c4 -140 13 -157 79 -152 45 3 54 32 12 40 -36 7 -42
25 -42 132 0 90 5 102 40 106 34 5 32 33 -2 37 -24 3 -41 25 -37 48 4 23 -3
34 -19 34 -10 0 -22 -3 -25 -7z"/>
<path d="M7938 4343 c-3 -33 -7 -38 -30 -41 -20 -2 -28 -9 -28 -22 0 -13 8
-20 28 -22 l27 -3 5 -121 c6 -139 13 -154 71 -154 31 0 39 4 39 18 0 10 -12
22 -30 29 l-30 11 0 110 0 111 27 3 c41 5 44 38 4 38 -27 0 -30 3 -33 38 -2
29 -8 38 -25 40 -19 3 -22 -2 -25 -35z"/>
<path d="M2764 4300 c-26 -10 -30 -15 -39 -57 -6 -28 -2 -37 23 -63 38 -37 68
-38 104 -3 67 64 -1 159 -88 123z"/>
<path d="M6908 4303 c-39 -6 -80 -58 -76 -97 4 -37 52 -73 111 -83 39 -6 77
-32 77 -53 0 -19 -40 -50 -65 -50 -35 0 -74 20 -80 42 -7 19 -55 26 -55 7 0
-21 34 -67 57 -78 34 -16 109 -14 143 4 33 17 50 43 50 77 0 40 -25 62 -101
88 -84 29 -102 49 -75 79 30 33 116 23 116 -14 0 -9 10 -15 25 -15 18 0 25 5
25 19 0 22 -47 71 -69 71 -9 0 -25 2 -36 4 -11 2 -32 1 -47 -1z"/>
<path d="M7676 4300 c-37 -12 -76 -48 -76 -72 0 -26 29 -22 56 7 16 18 33 25
59 25 42 0 65 -19 65 -53 0 -23 -5 -25 -72 -35 -59 -9 -77 -16 -95 -37 -55
-63 -7 -155 79 -155 24 0 54 7 66 16 22 15 24 15 36 -1 7 -9 20 -16 30 -14 17
4 18 15 17 123 -3 158 -17 187 -101 200 -19 3 -48 1 -64 -4z m104 -198 c0 -57
-29 -78 -97 -74 -28 2 -47 36 -39 68 7 25 45 41 104 43 31 1 32 -1 32 -37z"/>
<path d="M8180 4300 c-56 -13 -108 -90 -62 -90 10 0 28 11 40 25 30 34 94 35
116 3 30 -43 21 -56 -41 -63 -32 -3 -73 -13 -92 -23 -58 -29 -68 -96 -22 -143
23 -23 38 -29 71 -29 23 0 51 5 62 11 15 8 26 8 43 -1 43 -23 47 -13 46 120
-2 129 -10 158 -48 178 -30 16 -76 21 -113 12z m108 -183 c7 -36 -8 -65 -43
-81 -43 -21 -79 -13 -94 21 -14 31 6 69 41 76 49 10 93 3 96 -16z"/>
<path d="M5140 4288 c0 -14 67 -208 94 -271 28 -67 45 -49 103 113 29 80 53
151 53 158 0 8 -9 12 -22 10 -20 -3 -28 -19 -58 -110 -19 -60 -39 -108 -45
-108 -5 0 -26 49 -45 108 -30 91 -38 107 -57 110 -14 2 -23 -2 -23 -10z"/>
<path d="M5627 4294 c-11 -11 -8 -204 3 -246 6 -21 21 -44 33 -53 26 -18 108
-20 125 -3 10 10 17 10 32 0 48 -30 50 -23 50 148 l0 160 -25 0 -25 0 0 -118
c0 -102 -3 -121 -18 -135 -30 -27 -74 -31 -101 -10 -22 18 -24 27 -25 139 -2
110 -4 119 -22 122 -11 2 -23 0 -27 -4z"/>
<path d="M6415 4288 c-3 -8 -4 -79 -3 -158 l3 -145 25 0 25 0 0 155 c0 153 0
155 -23 158 -12 2 -24 -3 -27 -10z"/>
<path d="M1310 4201 c-55 -30 -102 -95 -108 -151 -6 -66 11 -109 62 -159 41
-40 49 -44 108 -48 73 -6 109 7 158 59 65 68 62 197 -5 265 -51 51 -152 67
-215 34z"/>
<path d="M1845 4197 c-76 -43 -100 -109 -73 -198 28 -96 169 -129 252 -60 24
20 55 88 56 121 0 46 -43 111 -91 137 -55 29 -93 29 -144 0z"/>
<path d="M2390 4193 c-30 -11 -70 -57 -76 -89 -6 -33 15 -93 42 -115 22 -18
83 -28 117 -18 65 18 99 117 60 176 -27 41 -96 63 -143 46z"/>
<path d="M2998 4153 c-21 -5 -47 -60 -40 -85 12 -48 99 -61 122 -18 35 65 -11
122 -82 103z"/>
<path d="M3230 4020 c-25 -25 -26 -52 -2 -73 37 -34 102 -8 102 40 0 20 -38
53 -60 53 -11 0 -29 -9 -40 -20z"/>
<path d="M2622 3957 c-50 -53 -49 -107 2 -162 36 -39 105 -39 145 -1 36 35 46
83 27 130 -18 43 -42 56 -104 56 -38 0 -53 -5 -70 -23z"/>
<path d="M3508 3949 c-44 -25 -3 -93 42 -69 26 14 26 56 1 70 -23 12 -22 12
-43 -1z"/>
<path d="M2122 3890 c-94 -68 -83 -212 20 -266 60 -31 150 -8 191 50 32 45 31
140 -1 181 -55 70 -142 85 -210 35z"/>
<path d="M1605 3828 c-46 -27 -81 -74 -96 -130 -13 -52 11 -125 58 -172 87
-87 251 -54 299 61 34 80 5 188 -63 232 -55 36 -144 40 -198 9z"/>
<path d="M2942 3804 c-27 -19 -52 -65 -52 -96 0 -36 49 -86 90 -93 53 -9 104
28 108 79 5 58 -5 85 -41 106 -39 24 -75 25 -105 4z"/>
<path d="M3255 3675 c-53 -52 -18 -155 52 -155 66 0 101 47 89 116 -12 61 -96
85 -141 39z"/>
<path d="M2487 3660 c-56 -25 -91 -88 -84 -147 17 -151 211 -182 278 -45 37
74 5 157 -75 193 -50 23 -70 23 -119 -1z"/>
<path d="M3620 3623 c-56 -21 -68 -87 -23 -123 72 -57 166 46 100 108 -23 21
-47 26 -77 15z"/>
<path d="M3942 3598 c-7 -7 -12 -26 -12 -43 0 -38 16 -55 51 -55 29 0 59 31
59 61 0 38 -70 65 -98 37z"/>
<path d="M1975 3524 c-16 -8 -40 -25 -52 -36 -25 -23 -53 -98 -53 -140 0 -83
98 -178 183 -178 51 0 121 35 152 76 20 26 28 51 32 94 11 117 -63 200 -178
200 -30 0 -67 -7 -84 -16z"/>
<path d="M2854 3473 c-83 -41 -85 -194 -2 -239 13 -8 47 -13 77 -14 50 0 57 3
93 40 32 35 38 47 38 86 0 108 -109 175 -206 127z"/>
<path d="M5115 3415 c-25 -24 -30 -50 -19 -91 11 -43 106 -60 137 -26 24 26
23 104 -1 125 -28 26 -88 22 -117 -8z"/>
<path d="M4506 3404 c-32 -32 -28 -92 8 -116 39 -26 106 14 106 63 0 53 -78
90 -114 53z"/>
<path d="M3299 3370 c-66 -19 -99 -62 -99 -130 0 -113 139 -162 217 -76 39 42
44 91 14 146 -21 41 -89 72 -132 60z"/>
<path d="M4110 3327 c-27 -9 -38 -20 -48 -50 -17 -55 6 -102 58 -118 71 -21
128 32 114 106 -10 56 -64 82 -124 62z"/>
<path d="M3685 3308 c-48 -28 -69 -72 -59 -124 15 -78 113 -110 181 -58 30 22
33 30 33 75 0 28 -5 59 -10 70 -23 41 -102 62 -145 37z"/>
<path d="M2401 3289 c-52 -21 -80 -50 -101 -106 -20 -54 -20 -58 -4 -109 30
-97 113 -145 214 -123 53 12 66 21 105 73 26 34 30 48 29 103 0 54 -5 68 -30
99 -16 20 -45 45 -64 55 -37 20 -109 24 -149 8z"/>
<path d="M4684 3196 c-35 -35 -39 -50 -28 -105 8 -38 64 -81 107 -81 106 0
148 150 57 203 -47 28 -97 22 -136 -17z"/>
<path d="M2870 3128 c-85 -24 -137 -102 -126 -192 8 -68 42 -109 112 -136 54
-20 59 -20 104 -5 54 18 92 54 114 107 21 49 20 81 -3 126 -26 50 -58 78 -110
97 -49 17 -44 17 -91 3z"/>
<path d="M4264 3081 c-11 -5 -33 -23 -47 -41 -45 -53 -36 -131 22 -190 10 -10
35 -15 81 -15 62 0 69 2 92 30 50 58 42 162 -16 200 -31 20 -102 29 -132 16z"/>
<path d="M3312 3025 c-78 -43 -117 -130 -88 -198 29 -71 83 -107 159 -107 172
0 218 239 58 306 -40 17 -98 17 -129 -1z"/>
<path d="M3749 2980 l-41 -39 4 -70 c4 -80 22 -108 84 -130 152 -55 272 132
156 245 -32 31 -40 34 -98 34 -60 0 -65 -2 -105 -40z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
